import axios from "axios";

const Request = () => {
    let config = {
        baseURL: (!process.env.REACT_APP_API_ENDPOINT ? "https://api.aftersend.com" : process.env.REACT_APP_API_ENDPOINT) + '/api'
    }

    const ax = axios.create(config);

    return ax;
}

export default Request;
