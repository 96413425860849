import React from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { changePassword } from "../API/Auth";
import { StepData } from "../API/AuthTypes";
import DynamicForm from "./DynamicFormComponent";
import { ModeComponentProps } from "./LoginComponent";

export interface FormDataMap {
    [key: string]: any
}

const ResetFormComponent = (props: ModeComponentProps) => {
    const [stepMessage, setStepMessage] = useState("");
    const [resetToken, setResetToken] = useState(null as string | null);

    const [params, setParams] = useSearchParams();

    React.useEffect(() => {
        const reset = params.get('reset');
        setResetToken(reset);
    }, [params]);

    const [formData, setFormData] = useState({
        email: '',
    } as FormDataMap);

    const [stepData] = useState({
        data: [],
        title: "Reset Password",
        description: "Enter your new password.",
        fields: [{
            name: 'password',
            type: 'password',
            title: 'New Password',
            default: undefined,
        }, 
        {
            name: 'confirmPassword',
            type: 'password',
            title: 'Confirm New Password',
            default: undefined,
        }],
        success: true,
        token: null,
        user_token: null
    } as StepData)

    const onSubmit = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();

        setStepMessage("");

        if (formData.password.length > 8) {
            if (formData.password === formData.confirmPassword) {
                if (resetToken) {
                    changePassword(resetToken, formData.password).then(r => {
                        props.setToast("Password updated. Return to login and use your updated credentials.");
                        setParams({});
                        props.setMode(null);
                    }).catch(e => {
                        props.setToast("An error occurred, let's try that from the top...");
                        props.setMode("forgot");
                        setParams({});
                    });
                }
            } else {
                setStepMessage("Password and confirm password must match.");
            }
        } else {
            setStepMessage("Password must be at least 8 characters.");
        }
    }

    return (
        <>
            <form className="dynamic-form" onSubmit={onSubmit}>
                <p className="dynamic-form-title">
                    { stepData.title }
                </p>
                <p className="dynamic-form-description">
                    { stepData.description }
                </p>
                <p className="dynamic-form-message">
                    { stepMessage }
                </p>
                <DynamicForm fields={stepData.fields} formData={formData} setFormData={setFormData}/>
                <button className="dynamic-form-submit" type="submit">Submit</button>
            </form>
        </>
    )
}

export default ResetFormComponent;
