import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ForgotFormComponent from "./ForgotFormComponent";
import LoginFormComponent from "./LoginFormComponent";
import ResetFormComponent from "./ResetFormComponent";

export interface FormDataMap {
    [key: string]: any
}

export interface LoginComponentProps {
    setToast: (arg0: string) => void
}

export interface ModeComponentProps {
    setMode: (arg0: "login" | "forgot" | "change" | null) => void,
    setToast: (arg0: string) => void
}

const LoginComponent = (props: LoginComponentProps) => {
    const [mode, setMode] = useState("login" as "login" | "forgot" | "change" | null);

    const [params] = useSearchParams();

    React.useEffect(() => {
        const reset = params.get('reset');

        if (reset) {
            setMode('change');
        }
    }, [params]);

    return (
        <>
            { mode === "login" ?
                <LoginFormComponent setMode={setMode} setToast={props.setToast}/>
            : null }
            { mode === "forgot" ?
                <ForgotFormComponent setMode={setMode} setToast={props.setToast}/>
            : null }
            { mode === "change" ?
                <ResetFormComponent setMode={setMode} setToast={props.setToast}/>
            : null }
        </>
    )
}

export default LoginComponent;
