import React from 'react';
import LoginComponent from './Components/LoginComponent';
import './styles/main.scss';
import logo from "./assets/aftersend.png";
import { BrowserRouter } from 'react-router-dom';

function App() {
  const [toast, setToast] = React.useState("");

  return (
    <BrowserRouter>
      <div className="App">
        <img className="dynamic-form-logo" src={logo} alt="Aftersend"/>
        { toast ? <p className="dynamic-form-toast">{ toast }</p> : null }
        <LoginComponent setToast={setToast}/>
      </div>
    </BrowserRouter>
  );
}

export default App;
