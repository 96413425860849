import { AuthApplicationMap, StepData } from "./AuthTypes";
import Request from "./Request"

const submitStep = (data: object, token: string | null) => {
    return Request().post('/auth/login', {
        data: data,
        token: token
    }).then(r => {
        return r.data as StepData;
    })
}

const getApplications = () => {
    return Request().get('/auth/applications').then(r => {
        return r.data as AuthApplicationMap;
    });
}

const forgotPassword = (email: string) => {
    return Request().post('/auth/reset', {
        email
    });
}

const changePassword = (token: string, newPassword: string) => {
    return Request().post('/auth/do-reset', {
        token: token,
        password: newPassword
    });
}

export {
    submitStep, getApplications, forgotPassword, changePassword
}
