import React from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getApplications, submitStep } from "../API/Auth";
import { AuthApplicationMap, StepData } from "../API/AuthTypes";
import DynamicForm from "./DynamicFormComponent";
import { ModeComponentProps } from "./LoginComponent";

export interface FormDataMap {
    [key: string]: any
}

const LoginFormComponent = (props: ModeComponentProps) => {
    const [applications, setApplications] = useState({} as AuthApplicationMap);
    const [application, setApplication] = useState("");
    const [next, setNext] = useState("");
    const [appsReady, setAppsReady] = useState(false);
    const [stepMessage, setStepMessage] = useState("");
    const [params] = useSearchParams();

    React.useEffect(() => {
        if (application) {
            getApplications().then(r => {
                if (r[application]) {
                    setApplications(r);
                    setAppsReady(true);
                } else {
                    window.location.href = (process.env.REACT_NO_APPLICATION_REDIRECT_URL ? process.env.REACT_NO_APPLICATION_REDIRECT_URL : "https://aftersend.com");
                }
            });
        }

        const paramApplication = params.get('application');
        const paramNext = params.get('next');
        if (paramApplication) {
            setApplication(paramApplication);
        } else {
            setApplication("accounts");
        }
        setNext(paramNext || "");
    }, [application, params]);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    } as FormDataMap);

    const [stepData, setStepData] = useState({
        data: [],
        title: "Login",
        description: "Please enter your username and password.",
        fields: [{
            name: 'email',
            type: 'email',
            title: 'Email Address',
            default: undefined,
        },
        {
            name: 'password',
            type: 'password',
            title: 'Password',
            default: undefined,
        }],
        success: true,
        token: null,
        user_token: null
    } as StepData)

    const onSubmit = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();

        setStepMessage("");

        submitStep(formData, stepData.token).then(stepResponse => {    
            if (stepResponse.success) {
                setFormData({});

                if (stepResponse.user_token) {
                    window.location.href = `${applications[application].callback_url}?${applications[application].token ? applications[application].token : 'token'}=${stepResponse.user_token}${next ? `&next=${next}` : ''}`;
                }

                setStepData(stepResponse);
            } else {
                setStepMessage(stepResponse.data['message']);
            }
        });
    }

    return (
        <>
            { appsReady ?
                <form className="dynamic-form" onSubmit={onSubmit}>
                    <p className="dynamic-form-title">
                        { stepData.title }
                    </p>
                    <p className="dynamic-form-description">
                        { stepData.description }
                    </p>
                    <p className="dynamic-form-message">
                        { stepMessage }
                    </p>
                    <DynamicForm fields={stepData.fields} formData={formData} setFormData={setFormData}/>
                    <p className="dynamic-form-info">
                        Authenticating for<br/>{ applications[application].title }
                    </p>
                    <button className="dynamic-form-submit" type="submit">Submit</button>
                    <button className="dynamic-form-alternate" onClick={() => { props.setMode("forgot") }}>Forgot Password</button>
                </form>
            : null }
        </>
    )
}

export default LoginFormComponent;
