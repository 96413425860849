import React from "react";
import { useState } from "react";
import { forgotPassword } from "../API/Auth";
import { StepData } from "../API/AuthTypes";
import DynamicForm from "./DynamicFormComponent";
import { ModeComponentProps } from "./LoginComponent";

export interface FormDataMap {
    [key: string]: any
}

const ForgotFormComponent = (props: ModeComponentProps) => {
    const [stepMessage, setStepMessage] = useState("");

    const [formData, setFormData] = useState({
        email: '',
    } as FormDataMap);

    const [stepData] = useState({
        data: [],
        title: "Forgot Password",
        description: "Please enter your email address, and we'll send you an email to reset your password.",
        fields: [{
            name: 'email',
            type: 'email',
            title: 'Email Address',
            default: undefined,
        }],
        success: true,
        token: null,
        user_token: null
    } as StepData)

    const onSubmit = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();

        setStepMessage("");

        // Submit Form

        props.setToast("Password reset sent! Follow the reset process and then return here to login.");
        props.setMode("login");

        forgotPassword(formData.email);
    }

    return (
        <>
            <form className="dynamic-form" onSubmit={onSubmit}>
                <p className="dynamic-form-title">
                    { stepData.title }
                </p>
                <p className="dynamic-form-description">
                    { stepData.description }
                </p>
                <p className="dynamic-form-message">
                    { stepMessage }
                </p>
                <DynamicForm fields={stepData.fields} formData={formData} setFormData={setFormData}/>
                <button className="dynamic-form-submit" type="submit">Submit</button>
                <button className="dynamic-form-alternate" onClick={() => { props.setMode("login") }}>I've remembered it...</button>
            </form>
        </>
    )
}

export default ForgotFormComponent;
