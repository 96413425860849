import { ChangeEvent } from "react";
import { FormDataMap } from "./LoginComponent";

export interface Field {
    name: string,
    type: string,
    title: string,
    default: string | number | undefined
}

interface DynamicFormProps {
    fields: Field[]
    formData: FormDataMap,
    setFormData: (arg0: FormDataMap) => void
}

const DynamicForm = (props: DynamicFormProps) => {
    const changeEvent = (event: ChangeEvent<HTMLInputElement>) => {
        const data = Object.assign({}, props.formData);
        data[event.target.name] = event.target.value;
        props.setFormData(data);
    }

    return (
        <div className="dynamic-form-fields">
            { props.fields.map((field, index) => {
                return (
                    <div className="dynamic-form-field" key={`dynamic_form_${field.name}`}>
                        <label htmlFor={`dynamic_form_${field.name}`}>
                            { field.title }
                        </label>

                        <input id={`dynamic_form_${field.name}`} name={field.name} type={field.type} defaultValue={field.default} value={props.formData[field.name]} onChange={changeEvent}/>
                    </div>
                );
            }) }
        </div>
    )
}

export default DynamicForm
